/* expanded with nsx-expanders:5.32.1, expansionResource org.normalizedsystems.angular:angular-expanders:3.2.0 */

.mat-snack-bar-container.alert-success {
  & div, & span, & mat-icon {
    color: #155724 !important;
  }
  background-color: #d4edda !important;
  border-color: #c3e6cb !important;
}

.mat-snack-bar-container.alert-error {
  & div, & span, & mat-icon {
    color: #721c24 !important;
  }
  background-color: #f8d7da !important;
  border-color: #f5c6cb !important;
}

.mat-snack-bar-container.alert-warning {
  & div, & span, & mat-icon {
    color: #856404 !important;
  }
  background-color: #fff3cd !important;
  border-color: #ffeeba !important;
}

.mat-snack-bar-container.alert-info {
  & div, & span, & mat-icon {
    color: #0c5460 !important;
  }
  background-color: #d1ecf1 !important;
  border-color: #bee5eb !important;
}
