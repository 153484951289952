/* expanded with nsx-expanders:5.32.1, expansionResource org.normalizedsystems.angular:angular-expanders:3.2.0 */

@use '@angular/material' as mat;
//import default typography
@use "src/styles/gen/default-typography";
//import default theme
@use "src/styles/gen/default-theme";
//import the mat-component style exposer mixins
@use "src/styles/gen/theme-to-material-component-exposer";
//import the custom-component CSS class defining mixin
@use "src/styles/gen/theme-to-css-classes-exposer";
//only include mat.core ONCE in application. We initialise it with default typography
@include mat.core(default-typography.$default-typography);
//expose the default-theme-used colors to mat-components included in the exposer mixin
@include theme-to-material-component-exposer.theme-full-to-material-component-exposer(default-theme.$default-theme);
//expose the default-theme-used colors to CSS-classes for custom components
@include theme-to-css-classes-exposer.theme-to-css-class-exposer(default-theme.$default-theme);

@import "src/app/ui/gen/alert/alert.component";

html, body {
  height: 100%;
}

body {
  margin: 0;
}

/* anchor:custom-scss:start */
.mat-select-trigger {
  height: fit-content;
}

@page {
  // TODO not supported on Firefox and Safari https://caniuse.com/css-paged-media
  size: A4;
  margin-left: 0;
}
/* anchor:custom-scss:end */

