/* expanded with nsx-expanders:5.32.1, expansionResource org.normalizedsystems.angular:angular-expanders:3.2.0 */

//import mat to use the includes for components
@use "@angular/material" as mat;

//import the theme-color-grabber mixin to expose CSS classes for use in non-Angular-Material-components
@import "src/styles/gen/theme-to-css-classes-exposer";

//TODO: Create a new MaterialModule that imports and exports all required MatXYZModules

// When importing a new matModule, add the mat.component-color($theme) here.
//--- !!! Don't forget to add the mat.component to both COLOR and THEME Mixins !!! ---//

@mixin theme-color-to-material-component-exposer($theme){
  //This mixin includes material __COLOR__ styles for all included mat-components.
  @include mat.core-color($theme);//core components
  @include mat.button-color($theme);
  @include mat.card-color($theme);
  @include mat.autocomplete-color($theme);
  @include mat.checkbox-color($theme);
  @include mat.chips-color($theme);
  @include mat.datepicker-color($theme);
  @include mat.dialog-color($theme);
  @include mat.form-field-color($theme);
  @include mat.icon-color($theme);
  @include mat.input-color($theme);
  @include mat.list-color($theme);
  @include mat.menu-color($theme);
  @include mat.paginator-color($theme);
  @include mat.sidenav-color($theme);
  @include mat.snack-bar-color($theme);
  @include mat.sort-color($theme);
  @include mat.table-color($theme);
  @include mat.toolbar-color($theme);
  @include mat.tree-color($theme);

  /* anchor:custom-color-include:start */
  @include mat.all-component-colors($theme);
  /* anchor:custom-color-include:end */
}

//both color and typography, used for default or themes than require a custom typography
@mixin theme-full-to-material-component-exposer($theme){
  //This mixin includes both material __COLOR__ and __TYPOGRAPHY__ styles for all included mat-components.
  @include mat.core-theme($theme);
  @include mat.button-theme($theme);
  @include mat.card-theme($theme);
  @include mat.autocomplete-theme($theme);
  @include mat.checkbox-theme($theme);
  @include mat.chips-theme($theme);
  @include mat.datepicker-theme($theme);
  @include mat.dialog-theme($theme);
  @include mat.form-field-theme($theme);
  @include mat.icon-theme($theme);
  @include mat.input-theme($theme);
  @include mat.list-theme($theme);
  @include mat.menu-theme($theme);
  @include mat.paginator-theme($theme);
  @include mat.sidenav-theme($theme);
  @include mat.snack-bar-theme($theme);
  @include mat.sort-theme($theme);
  @include mat.table-theme($theme);
  @include mat.toolbar-theme($theme);
  @include mat.tree-theme($theme);

  /* anchor:custom-theme-include:start */
  @include mat.all-component-themes($theme);
  /* anchor:custom-theme-include:end */
}

