/* expanded with nsx-expanders:5.32.1, expansionResource org.normalizedsystems.angular:angular-expanders:3.2.0 */

@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme-to-css-class-exposer($theme) {
  //###--- REGION: VARIABLES ---###//
      //--- SUBREGION: PRIMARY COLORS AND HUES ---//
          $primary-palette: map.get($theme, primary);
          //MAIN
          $primary: mat.get-color-from-palette($primary-palette, 500);
          $primary-lighter: mat.get-color-from-palette($primary-palette, 300);
          $primary-darker: mat.get-color-from-palette($primary-palette, 700);
          //CONTRAST
          $primary-contrast: mat.get-contrast-color-from-palette($primary-palette, 500);
          $primary-contrast-lighter: mat.get-contrast-color-from-palette($primary-palette, 300);
          $primary-contrast-darker: mat.get-contrast-color-from-palette($primary-palette, 700);
      //--- END-SUBREGION ---//

      //--- SUBREGION: ACCENT COLORS AND HUES ---//
          $accent-palette: map.get($theme, accent);
          //MAIN
          $accent: mat.get-color-from-palette($accent-palette, 500);
          $accent-lighter: mat.get-color-from-palette($accent-palette, 300);
          $accent-darker: mat.get-color-from-palette($accent-palette, 700);
          //CONTRAST
          $accent-contrast: mat.get-contrast-color-from-palette($accent-palette, 500);
          $accent-contrast-lighter: mat.get-contrast-color-from-palette($accent-palette, 300);
          $accent-contrast-darker: mat.get-contrast-color-from-palette($accent-palette, 700);
      //--- END-SUBREGION ---//

      //--- SUBREGION: WARN COLORS ---//
          $warn-palette: map.get($theme, warn);
          //MAIN
          $warn: mat.get-color-from-palette($warn-palette, 500);
          //CONTRAST
          $warn-contrast: mat.get-contrast-color-from-palette($warn-palette, 500);
      //--- END-SUBREGION ---//

      //--- SUBREGION: TYPOGRAPHY ---//
          $typography-config: mat.get-typography-config(map.get($theme, typography));
          $font-family: mat.font-family($typography-config);
      //--- END-SUBREGION ---.//

      /* anchor:custom-variables:start */
      /* anchor:custom-variables:end */
  //###--- END-REGION ---###//

  //###--- REGION: CSS VARIABLES ---###//
  *{
    --primary-50: #{mat.get-color-from-palette($primary-palette, 50)};
    --primary-100: #{mat.get-color-from-palette($primary-palette, 100)};
    --primary-200: #{mat.get-color-from-palette($primary-palette, 200)};
    --primary-300: #{mat.get-color-from-palette($primary-palette, 300)};
    --primary-400: #{mat.get-color-from-palette($primary-palette, 400)};
    --primary-500: #{mat.get-color-from-palette($primary-palette, 500)};
    --primary-600: #{mat.get-color-from-palette($primary-palette, 600)};
    --primary-700: #{mat.get-color-from-palette($primary-palette, 700)};
    --primary-800: #{mat.get-color-from-palette($primary-palette, 800)};
    --primary-900: #{mat.get-color-from-palette($primary-palette, 900)};
    --primary-A100: #{mat.get-color-from-palette($primary-palette, A100)};
    --primary-A200: #{mat.get-color-from-palette($primary-palette, A200)};
    --primary-A400: #{mat.get-color-from-palette($primary-palette, A400)};
    --primary-A700: #{mat.get-color-from-palette($primary-palette, A700)};
    --primary-contrast-50: #{mat.get-contrast-color-from-palette($primary-palette, 50)};
    --primary-contrast-100: #{mat.get-contrast-color-from-palette($primary-palette, 100)};
    --primary-contrast-200: #{mat.get-contrast-color-from-palette($primary-palette, 200)};
    --primary-contrast-300: #{mat.get-contrast-color-from-palette($primary-palette, 300)};
    --primary-contrast-400: #{mat.get-contrast-color-from-palette($primary-palette, 400)};
    --primary-contrast-500: #{mat.get-contrast-color-from-palette($primary-palette, 500)};
    --primary-contrast-600: #{mat.get-contrast-color-from-palette($primary-palette, 600)};
    --primary-contrast-700: #{mat.get-contrast-color-from-palette($primary-palette, 700)};
    --primary-contrast-800: #{mat.get-contrast-color-from-palette($primary-palette, 800)};
    --primary-contrast-900: #{mat.get-contrast-color-from-palette($primary-palette, 900)};
    --primary-contrast-A100: #{mat.get-contrast-color-from-palette($primary-palette, A100)};
    --primary-contrast-A200: #{mat.get-contrast-color-from-palette($primary-palette, A200)};
    --primary-contrast-A400: #{mat.get-contrast-color-from-palette($primary-palette, A400)};
    --primary-contrast-A700: #{mat.get-contrast-color-from-palette($primary-palette, A700)};

    --accent-50: #{mat.get-color-from-palette($accent-palette, 50)};
    --accent-100: #{mat.get-color-from-palette($accent-palette, 100)};
    --accent-200: #{mat.get-color-from-palette($accent-palette, 200)};
    --accent-300: #{mat.get-color-from-palette($accent-palette, 300)};
    --accent-400: #{mat.get-color-from-palette($accent-palette, 400)};
    --accent-500: #{mat.get-color-from-palette($accent-palette, 500)};
    --accent-600: #{mat.get-color-from-palette($accent-palette, 600)};
    --accent-700: #{mat.get-color-from-palette($accent-palette, 700)};
    --accent-800: #{mat.get-color-from-palette($accent-palette, 800)};
    --accent-900: #{mat.get-color-from-palette($accent-palette, 900)};
    --accent-A100: #{mat.get-color-from-palette($accent-palette, A100)};
    --accent-A200: #{mat.get-color-from-palette($accent-palette, A200)};
    --accent-A400: #{mat.get-color-from-palette($accent-palette, A400)};
    --accent-A700: #{mat.get-color-from-palette($accent-palette, A700)};
    --accent-contrast-50: #{mat.get-contrast-color-from-palette($accent-palette, 50)};
    --accent-contrast-100: #{mat.get-contrast-color-from-palette($accent-palette, 100)};
    --accent-contrast-200: #{mat.get-contrast-color-from-palette($accent-palette, 200)};
    --accent-contrast-300: #{mat.get-contrast-color-from-palette($accent-palette, 300)};
    --accent-contrast-400: #{mat.get-contrast-color-from-palette($accent-palette, 400)};
    --accent-contrast-500: #{mat.get-contrast-color-from-palette($accent-palette, 500)};
    --accent-contrast-600: #{mat.get-contrast-color-from-palette($accent-palette, 600)};
    --accent-contrast-700: #{mat.get-contrast-color-from-palette($accent-palette, 700)};
    --accent-contrast-800: #{mat.get-contrast-color-from-palette($accent-palette, 800)};
    --accent-contrast-900: #{mat.get-contrast-color-from-palette($accent-palette, 900)};
    --accent-contrast-A100: #{mat.get-contrast-color-from-palette($accent-palette, A100)};
    --accent-contrast-A200: #{mat.get-contrast-color-from-palette($accent-palette, A200)};
    --accent-contrast-A400: #{mat.get-contrast-color-from-palette($accent-palette, A400)};
    --accent-contrast-A700: #{mat.get-contrast-color-from-palette($accent-palette, A700)};

    --warn-50: #{mat.get-color-from-palette($warn-palette, 50)};
    --warn-100: #{mat.get-color-from-palette($warn-palette, 100)};
    --warn-200: #{mat.get-color-from-palette($warn-palette, 200)};
    --warn-300: #{mat.get-color-from-palette($warn-palette, 300)};
    --warn-400: #{mat.get-color-from-palette($warn-palette, 400)};
    --warn-500: #{mat.get-color-from-palette($warn-palette, 500)};
    --warn-600: #{mat.get-color-from-palette($warn-palette, 600)};
    --warn-700: #{mat.get-color-from-palette($warn-palette, 700)};
    --warn-800: #{mat.get-color-from-palette($warn-palette, 800)};
    --warn-900: #{mat.get-color-from-palette($warn-palette, 900)};
    --warn-A100: #{mat.get-color-from-palette($warn-palette, A100)};
    --warn-A200: #{mat.get-color-from-palette($warn-palette, A200)};
    --warn-A400: #{mat.get-color-from-palette($warn-palette, A400)};
    --warn-A700: #{mat.get-color-from-palette($warn-palette, A700)};
    --warn-contrast-50: #{mat.get-contrast-color-from-palette($warn-palette, 50)};
    --warn-contrast-100: #{mat.get-contrast-color-from-palette($warn-palette, 100)};
    --warn-contrast-200: #{mat.get-contrast-color-from-palette($warn-palette, 200)};
    --warn-contrast-300: #{mat.get-contrast-color-from-palette($warn-palette, 300)};
    --warn-contrast-400: #{mat.get-contrast-color-from-palette($warn-palette, 400)};
    --warn-contrast-500: #{mat.get-contrast-color-from-palette($warn-palette, 500)};
    --warn-contrast-600: #{mat.get-contrast-color-from-palette($warn-palette, 600)};
    --warn-contrast-700: #{mat.get-contrast-color-from-palette($warn-palette, 700)};
    --warn-contrast-800: #{mat.get-contrast-color-from-palette($warn-palette, 800)};
    --warn-contrast-900: #{mat.get-contrast-color-from-palette($warn-palette, 900)};
    --warn-contrast-A100: #{mat.get-contrast-color-from-palette($warn-palette, A100)};
    --warn-contrast-A200: #{mat.get-contrast-color-from-palette($warn-palette, A200)};
    --warn-contrast-A400: #{mat.get-contrast-color-from-palette($warn-palette, A400)};
    --warn-contrast-A700: #{mat.get-contrast-color-from-palette($warn-palette, A700)};

    /* anchor:custom-css-variables:start */
    /* anchor:custom-css-variables:end */
  }
  //###--- END-REGION ---###//

  //###--- REGION: EXPOSED CSS CLASSES ---###//
      //--- SUBREGION: (TEXT-)COLOR ---//
          .primary-color{
            color: $primary !important;
          }
          .primary-lighter-color{
            color: $primary-lighter !important;
          }
          .primary-darker-color{
            color: $primary-darker !important;
          }
          .primary-contrast-color{
            color: $primary-contrast !important;
          }
          .primary-contrast-lighter-color{
            color: $primary-contrast-lighter !important;
          }
          .primary-contrast-darker-color{
            color: $primary-contrast-darker !important;
          }
          .accent-color{
            color: $accent !important;
          }
          .accent-lighter-color{
            color: $accent-lighter !important;
          }
          .accent-darker-color{
            color: $accent-darker !important;
          }
          .accent-contrast-color{
            color: $accent-contrast !important;
          }
          .accent-contrast-lighter-color{
            color: $accent-contrast-lighter !important;
          }
          .accent-contrast-darker-color{
            color: $accent-contrast-darker !important;
          }
          .warn-color{
            color: $warn !important;
          }
          .warn-contrast-color{
            color: $warn-contrast !important;
          }
      //--- END-SUBREGION ---//

      //--- SUBREGION: BACKGROUND-COLOR ---//
          .primary-background{
            background-color: $primary !important;
          }
          .primary-lighter-background{
            background-color: $primary-lighter !important;
          }
          .primary-darker-background{
            background-color: $primary-darker !important;
          }
          .primary-contrast-background{
            background-color: $primary-contrast !important;
          }
          .primary-contrast-lighter-background{
            background-color: $primary-contrast-lighter !important;
          }
          .primary-contrast-darker-background{
            background-color: $primary-contrast-darker !important;
          }
          .accent-background{
            background-color: $accent !important;
          }
          .accent-lighter-background{
            background-color: $accent-lighter !important;
          }
          .accent-darker-background{
            background-color: $accent-darker !important;
          }
          .accent-contrast-background{
            background-color: $accent-contrast !important;
          }
          .accent-contrast-lighter-background{
            background-color: $accent-contrast-lighter !important;
          }
          .accent-contrast-darker-background{
            background-color: $accent-contrast-darker !important;
          }
          .warn-background{
            background-color: $warn !important;
          }
          .warn-contrast-background{
            background-color: $warn-contrast !important;
          }
      //--- END-SUBREGION ---//

      //--- SUBREGION: BORDER-COLOR ---//
          .primary-border{
            border-color: $primary !important;
          }
          .primary-lighter-border{
            border-color: $primary-lighter !important;
          }
          .primary-darker-border{
            border-color: $primary-darker !important;
          }
          .primary-contrast-border{
            border-color: $primary-contrast !important;
          }
          .primary-contrast-lighter-border{
            border-color: $primary-contrast-lighter !important;
          }
          .primary-contrast-darker-border{
            border-color: $primary-contrast-darker !important;
          }
          .accent-border{
            border-color: $accent !important;
          }
          .accent-lighter-border{
            border-color: $accent-lighter !important;
          }
          .accent-darker-border{
            border-color: $accent-darker !important;
          }
          .accent-contrast-border{
            border-color: $accent-contrast !important;
          }
          .accent-contrast-lighter-border{
            border-color: $accent-contrast-lighter !important;
          }
          .accent-contrast-darker-border{
            border-color: $accent-contrast-darker !important;
          }
          .warn-border{
            border-color: $warn !important;
          }
          .warn-contrast-border{
            border-color: $warn-contrast !important;
          }
      //--- END-SUBREGION ---//

      //--- SUBREGION: FILL-COLOR ---//
          .primary-fill{
            fill: $primary !important;
          }
          .primary-lighter-fill{
            fill: $primary-lighter !important;
          }
          .primary-darker-fill{
            fill: $primary-darker !important;
          }
          .primary-contrast-fill{
            fill: $primary-contrast !important;
          }
          .primary-contrast-lighter-fill{
            fill: $primary-contrast-lighter !important;
          }
          .primary-contrast-darker-fill{
            fill: $primary-contrast-darker !important;
          }
          .accent-fill{
            fill: $accent !important;
          }
          .accent-lighter-fill{
            fill: $accent-lighter !important;
          }
          .accent-darker-fill{
            fill: $accent-darker !important;
          }
          .accent-contrast-fill{
            fill: $accent-contrast !important;
          }
          .accent-contrast-lighter-fill{
            fill: $accent-contrast-lighter !important;
          }
          .accent-contrast-darker-fill{
            fill: $accent-contrast-darker !important;
          }
          .warn-fill{
            fill: $warn !important;
          }
          .warn-contrast-fill{
            fill: $warn-contrast !important;
          }
      //--- END-SUBREGION ---//

      //--- SUBREGION: STROKE-COLOR ---//
          .primary-stroke{
            stroke: $primary !important;
          }
          .primary-lighter-stroke{
            stroke: $primary-lighter !important;
          }
          .primary-darker-stroke{
            stroke: $primary-darker !important;
          }
          .primary-contrast-stroke{
            stroke: $primary-contrast !important;
          }
          .primary-contrast-lighter-stroke{
            stroke: $primary-contrast-lighter !important;
          }
          .primary-contrast-darker-stroke{
            stroke: $primary-contrast-darker !important;
          }
          .accent-stroke{
            stroke: $accent !important;
          }
          .accent-lighter-stroke{
            stroke: $accent-lighter !important;
          }
          .accent-darker-stroke{
            stroke: $accent-darker !important;
          }
          .accent-contrast-stroke{
            stroke: $accent-contrast !important;
          }
          .accent-contrast-lighter-stroke{
            stroke: $accent-contrast-lighter !important;
          }
          .accent-contrast-darker-stroke{
            stroke: $accent-contrast-darker !important;
          }
          .warn-stroke{
            stroke: $warn !important;
          }
          .warn-contrast-stroke{
            stroke: $warn-contrast !important;
          }
      //--- END-SUBREGION ---//

      //--- SUBREGION: TYPOGRAPHY ---//
          .typography-font-family{
            font-family: $font-family;
          }
      //--- END-SUBREGION ---//

      /* anchor:custom-css-classes:start */
      /* anchor:custom-css-classes:end */
  //###--- END-REGION ---###//
}

